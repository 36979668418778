$component-name: section-title;

.#{$component-name} {
  margin-bottom: 0;
  font-size: 2.1875rem;
  text-transform: uppercase;
  font-family: Intro Head R;

  @media screen and (max-width: 1200px) {
    font-size: 1.875rem;
  }
  @include media-breakpoint-down(sm) {
    font-size: 1.375rem;
  }

  span {
    font-weight: 200;
  }
  
  &.dotted {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    &:before,
    &:after {
      content: '';
      display: inline-block;
      top: calc(50% - .25rem);
      width: 3rem;
      height: .5rem;
      margin-left: .75rem;
      margin-right: .75rem;
      background-image: url('../img/dots.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      vertical-align: middle;

      @include media-breakpoint-down(sm) {
        width: 2rem;
      }
    }
  }
}

.section-subtitle {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-family: Intro Head R;

  @media screen and (max-width: 1200px) {
    font-size: 1.875rem;
  }
  @include media-breakpoint-down(sm) {
    font-size: 1.5rem;
  }
}

.dotted {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  &:before,
  &:after {
    content: '';
    display: inline-block;
    top: calc(50% - .25rem);
    width: 3rem;
    height: .5rem;
    margin-left: .75rem;
    margin-right: .75rem;
    background-image: url('../img/dots.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    vertical-align: middle;
  }
}

;@import "sass-embedded-legacy-load-done:1503";