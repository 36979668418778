$component-name: category-tree;

#left-column {
  --#{$component-name}-title-color: #{$gray-800};
  --#{$component-name}-title-size: 1.5rem;
  --#{$component-name}-title-spacing: 1rem;
  --#{$component-name}-subtitle-color: #{$gray-800};
  --#{$component-name}-subtitle-size: 1rem;
  --#{$component-name}-subtitle-spacing: 0.25rem;
  --#{$component-name}-border-bottom: 1px solid #{$gray-200};
}

.#{$component-name} {
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: var(--#{$component-name}-border-bottom);

  &__child {
    padding: 0;
    border: none;
  }

  &__title {
    padding: 0;
    margin-bottom: var(--#{$component-name}-title-spacing);
    font-size: var(--#{$component-name}-title-size);
    border: none;

    &__link {
      display: block;
      font-weight: 600;
      line-height: 2rem;
      color: var(--#{$component-name}-title-color);
    }
  }

  &__item {
    &__link {
      display: block;
      padding: var(--#{$component-name}-subtitle-spacing) 0;
      font-size: var(--#{$component-name}-subtitle-size);
      font-weight: 600;
      color: var(--#{$component-name}-subtitle-color);
    }

    &__header {
      &--parent {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
          padding: 0;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:1506";