#home-slider {
  .carousel-item {
    min-height: 33.69vw;

    > .carousel-link {
      align-items: start;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  a:not(.carousel-link) {
    @extend .btn; 
    @extend .btn-primary;
    font-weight: 700;
  }

  figure.carousel-content {
    height: 100%;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 70%);
    }
  }

  .carousel-caption {
    right: 8.3333333%;
    left: 8.3333333%;
    width: calc(100% - 16.6666%);
    @include media-breakpoint-down(md) {
      right: 3.5rem;
      left: 3.5rem;
      width: calc(100% - 7rem);
    }
  }
}

.ratio-homeSlider {
  min-height: 250px;
  width: 100%;
  .carousel  {
    height: 100%;
  }
  @include media-breakpoint-up(md) {
    min-height: 300px;
  }
}

;@import "sass-embedded-legacy-load-done:1571";