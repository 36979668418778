$font-family-highlight:   "Intro Head R", serif;
$font-family-philo:       "Nunito", serif;
  
$font-size-base:          1rem;
$line-height-base:        1.5;
$font-weight-base:        400;
  
$font-family-base:        "Nunito",$font-family-sans-serif;
$headings-font-family:    $font-family-base;
$headings-font-weight:    600;
  
$h1-font-size:            $font-size-base * 2.5;
$h2-font-size:            $font-size-base * 1.5;
$h3-font-size:            $font-size-base * 1.125;
$h4-font-size:            $font-size-base;
$h5-font-size:            $font-size-base;
$h6-font-size:            $font-size-base * 0.9375;
  
$headings-color:          #2C2A29;
$headings-margin-bottom:  $spacer;
$headings-line-height:    1.3;
$display-font-weight:     400;

// scss-docs-start display-headings
$display-font-sizes: (
  1: 3.4375rem,
  2: 1.75rem,
  3: 1.5rem,
  4: 1.25rem,
  5: 1.125rem,
  6: 1rem
);

;@import "sass-embedded-legacy-load-done:1283";