$component-name: header-bottom;

.#{$component-name} {
  --header-top-border-bottom: #{$header-top-border-bottom};
  --header-bottom-border-bottom: #{$header-bottom-border-bottom};
  background-color: #fff;
  
  .navbar-brand {
    padding: 0;
  }

  .nav-link {
    &:active,
    &.active {
      color: var(--bs-white);
    }

    &:active:hover {
      color: var(--bs-white);
    }
  }
}

;@import "sass-embedded-legacy-load-done:1608";