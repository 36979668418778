$component-name: account-menu;

.#{$component-name} {
  display: flex;
  flex-direction: column;

  > a {
    display: block;

    /* Width to properly override hooked modules */
    width: 100%;
    padding: 0.4rem 0.5rem;
    border-radius: 4px;
    margin-bottom: .5rem;

    &:hover,
    &.active {
      background: lighten($gray-100, 1%);

      .link-item {
        &,
        i {
          color: var(--bs-primary);
        }
      }
    }

    &.#{$component-name}--signout {
      .link-item {
        color: var(--bs-danger);

        &,
        i {
          color: var(--bs-danger);
        }
      }
    }
  }

  .link-item {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    color: var(--bs-gray-800);
    transition: all 0.25s ease-out;

    i {
      margin-right: 0.75rem;
    }
  }

  &__back {
    display: block;
    margin-bottom: 1rem;
  }
}

;@import "sass-embedded-legacy-load-done:1523";