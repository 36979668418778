.toast-container {
  z-index: $zindex-fixed;

  .toast {
    .btn-close {
      &:focus {
        box-shadow: none;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:1459";