$input-padding-y:                       0.5rem;
$input-padding-x:                       1rem;
$input-font-size:                       1rem;
$input-font-weight:                     400;
$input-line-height:                     1.5;

$input-color:                           $gray-800;
$input-border-color:                    $gray-400;

$input-border-radius:                   4px;
$input-border-radius-sm:                4px;
$input-border-radius-lg:                4px;

$input-placeholder-color:               $gray-300;
$input-plaintext-color:                 $gray-800;

;@import "sass-embedded-legacy-load-done:1294";