$component-name: left-block;

#left-column {
  --#{$component-name}-title-color: #{$gray-800};
  --#{$component-name}-title-size: 1.5rem;
  --#{$component-name}-title-spacing: 1rem;
  --#{$component-name}-subtitle-color: #{$gray-800};
  --#{$component-name}-subtitle-size: 1rem;
  --#{$component-name}-subtitle-spacing: 0.25rem;
  --#{$component-name}-border-bottom: 1px solid #{$gray-200};
}

.#{$component-name} {
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: var(--#{$component-name}-border-bottom);

  &__title {
    padding: 0;
    margin-bottom: var(--#{$component-name}-title-spacing);
    font-size: var(--#{$component-name}-title-size);
    font-weight: 600;
    border: none;
  }

  &__content {
    &--list {
      margin-bottom: 0;
    }
  }

  &__link {
    display: block;
    padding: var(--#{$component-name}-subtitle-spacing) 0;
    font-size: var(--#{$component-name}-subtitle-size);
    font-weight: 600;
    color: var(--#{$component-name}-subtitle-color);
  }
}

;@import "sass-embedded-legacy-load-done:1485";