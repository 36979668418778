$component-name: brand;

.#{$component-name} {
  text-align: center;

  & &__link {
    font-size: 1rem;
    color: var(--bs-gray-800);
  }

  p {
    margin: 0;
  }

  & &__infos {
    margin-top: 0.5rem;
  }

  & &__products {
    a {
      font-size: 0.85rem;
    }
  }
}

;@import "sass-embedded-legacy-load-done:1529";