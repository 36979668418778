$component-name: mailalerts-account-line;

.#{$component-name} {
  padding: 1rem;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius-xl);

  &__product {
    min-height: 54px;

    &__name {
      font-size: 0.875rem;
      font-weight: 600;
      color: var(--bs-body-color);
    }

    &__attributes {
      font-size: 0.75rem;
      color: var(--bs-body-color);
    }
  }

  &__remove {
    position: absolute;
    top: 0;
    right: 0;
    color: var(--bs-black);
  }
}

;@import "sass-embedded-legacy-load-done:1549";