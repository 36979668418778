.sw-pgn, .p-hover:hover .sw-pgn {
	margin-right: auto;
	margin-left: auto;
}

.cb {
	.reverse {
		@include media-breakpoint-up(md) {
			flex-direction: row-reverse;
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 2.25rem;
		height: 4.5rem;
		@include media-breakpoint-down(md) {
			height: 2.5rem;
		}
		top: calc(50% - 2rem);
		padding: 0;
		border-radius: 0;
		box-shadow: none;
		margin-top: -2.25rem;
		background-color: #fff;

		&:after {
			display: block;
			font-family: "Material Icons";
			content: "\e5cb";
			font-size: 2rem;
			color: $primary;
			border-color: $primary;
		}

		&:hover {
			background-color: $primary;
			&:after {
				color: #fff;
				border-color: #fff;
			}
		}
	}
	.swiper-button-prev {
		left: 1rem;
		@media screen and (min-width: 1200px) {
			left: 3.5rem;
		}
	}
	.swiper-button-next {
		right: 1rem;
		@media screen and (min-width: 1200px) {
			right: 3.5rem;
		}
		&:after {
			transform: rotate(180deg);
		}
	}

	.cb-wrapper .hidden {
		display: none;
	}

	.cb-item {
		text-align: inherit;
		position: relative;
	}
}

.cb-item-content{
	position: relative;
}

.cb-item-content .cb-img {
	display: inline-block;
	width: 100%;
	height: auto;
}

.cb-item-content .cb-img.secondary-image,
.cb-item-content:hover .cb-img.primary-image {
	display: none;
}

.cb-item-content:hover .cb-img.secondary-image {
	display: inline-block;
}

.html-over .custom-html {
	display: table;
	margin: auto;
	position: absolute;
	width: 100%;
	top: 0; left: 0; bottom: 0; right: 0;
}

.img-rb .cb-item-content img {
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.cb-wrapper .cb-carousel.swiper-container-horizontal {
	visibility: visible;
	max-height: none;
}

/* accordion */
.cb-wrapper.type-4 {
	margin-top: 1px;
}

.cb-wrapper.type-4 .cb-item {
	width: 100%;
}

.cb-wrapper.type-4 .cb-item-title {
	text-align: left;
	padding: 10px;
	border: 1px solid #D0D5DB;
	margin-top: -1px;
	cursor: pointer;
}

.cb-wrapper.type-4 .cb-item-title:before {
	content: '+';
	font: bold 1.2em/1 Arial;
	color: #AAB2BD;
	margin-right: 5px;
}

.cb-wrapper.type-4 .cb-item.active .cb-item-title:before {
	content: '−';
}

/* grid values for 480 px. They are not present in BS 3 */
@media (max-width: 479px) {
	[class*=".col-xxs-"]{ float: left; }
	.col-xxs-1  { width: 8.3%; }
	.col-xxs-2  { width: 16.6%; }
	.col-xxs-3  { width: 25%; }
	.col-xxs-4  { width: 33.3%; }
	.col-xxs-5  { width: 41.8%; }
	.col-xxs-6  { width: 50%; }
	.col-xxs-7  { width: 58.3%; }
	.col-xxs-8  { width: 66.6%; }
	.col-xxs-9  { width: 75%; }
	.col-xxs-10 { width: 83.3%; }
	.col-xxs-11 { width: 91.6%; }
	.col-xxs-12 { width: 100%; }
}

/* special classes used in layout */
.cb .compact-gutters-container {
	margin: 0 -5px;
}

.cb .compact-gutters {
	padding: 0 5px 9px 5px;
}

.box-shadow, .img-box-shadow img {
	box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);
}
/* since 2.9.7 */

.ratio-1x1 {
	--bs-aspect-ratio:calc(920 / 1920 * 100%);
}

.displayCustomBanners1 {
	width: 100%;
}

.displayHome {
	display: flex;
	flex-direction: column;

	.collections {
	  order: 1;
	}
	.presentation {
	  order: 2;
	}
}

.home-cat .cb-item {
	@extend .col-with-padding;
	.cb-item-content {
		background:$body-color;
		overflow:hidden;
		img {
			opacity:0.6;
			width:100%;	
			transition:all .5s ease-out;
		}
	}
	.custom-html {
		text-align:left;
		top:auto;
		bottom:1.5rem;
		padding-left:1.5rem;
		color:$white;
		h2 {
			font-weight:600;
			color:$white;
			margin-bottom:0.1rem;	
		}
		p {
			@include font-size(1.25rem);	
		}
		em {
			font-style:normal;
			color:$secondary;
			@extend .btn;	
			@extend .btn-secondary;	
			border-color:transparent;
		}

	}
}

.presentation {
	@media screen and (max-width: 1500px) {
		width: calc(100% - 6rem);
		margin-left: 3rem;		
	}
	@include media-breakpoint-down(lg) {
		width: calc(100% - 2rem);
		margin-left: 1rem;		
	}
    @include media-breakpoint-down(md) {
		width: 100%;
		margin-left: 0;	
    }

	.col-md-7 {
	    width: 57%;
	    @include media-breakpoint-down(md) {
	    	width: 100%;
	    }

	    .cb-item-content {
	    	height: 100%;
	    	img {
		    	object-fit: cover;
  				height: 100%;
	    	}
	    }
  	}

	.col-md-5 {
	    width: 43%;
	    @include media-breakpoint-down(md) {
	    	width: 100%;
	    }

	    p:nth-of-type(1) {
	    	margin-bottom: 1.5rem;
	    	font-family: $font-family-highlight;
	    	font-size: 1.5rem;
  			@media screen and (max-width: 1500px) {
  				font-size: 1.25rem;
  			}
	    }
		a {
			@extend .btn-tertiary;
			display: inline-block;
			text-decoration: none;
			margin-top: 1rem;
		}
  	}
  	&.w-10 {
  		.col-md-5 {
  			padding-top: 3rem;
  			padding-bottom: 8rem;
  			padding-left: 5rem;
  			@media screen and (max-width: 1500px) {
  				padding-left: 3rem;
  			}
		    @include media-breakpoint-down(md) {
		    	padding-left: 0;
		    	padding-top: 1rem;
		    	padding-bottom: 1rem;
		    }
  		}
  	}
  	&.w-12 {
  		margin-top: 1rem;
	    @include media-breakpoint-up(md) {
  			margin-top: -5rem;
	    }
  		img {
		    @include media-breakpoint-up(md) {
	  			border-top: 1rem solid white;
	  			border-left: 1rem solid white;
	  		}
  		}
  		.col-md-5 {
  			padding-top: 8rem;
  			padding-bottom: 3rem;
		    @include media-breakpoint-down(md) {
		    	padding-top: 1rem;
		    	padding-bottom: 1rem;

		    	* {
		    		text-align: left!important;
		    	}
		    }
  		}
  	}

	h2 {
		font-weight: 400;
		margin-bottom: 0;
		text-transform: uppercase;
		font-size: 2.5rem;
		font-family: Intro Head R;

		@media screen and (max-width: 1500px) {
  			font-size: 2rem;
		}
		@include media-breakpoint-down(md) {
  			font-size: 1.5rem;
		}
	}
}

.list-categories {
	.cb-carousel {
		width: 100%;
	}

	.cb-item {
  		padding: 0 0.75rem;
  		@include media-breakpoint-down(md) {
  			padding: 0 .5rem;
  		}
	}

	.cb-item-content {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		> a {
			width: 100%;
			height: 100%;
			padding: 0;
		}

		.cb-img {
			height: 100%;
  			object-fit: cover;
		}
	}

	.custom-html {
		position: absolute;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
	}

	p {
		color: #fff;
		font-size: 2.5rem;
		margin-bottom: 1rem;
		font-family: $font-family-highlight;
		@media screen and (max-width: 1200px) {
			font-size: 2rem;
		}

		&:nth-of-type(2) {
			@extend .btn-secondary;
			display: inline-block;
			font-weight: 700;
			font-family: $font-family-base;
		}
	}

	.sw-pgn {
		@include media-breakpoint-down(sm) {
			margin-top: -2rem;
		}
	}
}

.collections {
	background-color: $black;
	color: #fff;

	.cb-item-content {
		> a {
			padding: 0;
		}
	}

	&.swiper {
		position: relative;
		margin-right: -1rem;
		margin-left: -1rem;

		.swiper-wrapper {
			padding: 0;
		}

		.swiper-slide {
			width: 29.5%;
			margin-left: 0.75rem;
			margin-right: 0.75rem;
			@include media-breakpoint-down(lg) {
				width: 57%;
			}
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
		}

		.custom-html {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 3rem;
			background: linear-gradient(0deg, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, 0) 70%);
		}

		p {
			color: #fff;
			font-size: 2.1875rem;
			margin-bottom: 1rem;
			text-transform: uppercase;
			font-family: $font-family-highlight;
			@media screen and (max-width: 1200px) {
				font-size: 1.875rem;
			}

			&:nth-of-type(2) {
				font-family: $font-family-base;
				@extend .btn-secondary;
				display: inline-block;
				font-weight: 700;
			}
		}
	}

	&.w-14 {
		margin-left: -1rem;
		margin-right: -1rem;
		padding-top: 3.5rem;
		@include media-breakpoint-down(lg) {
			padding-top: 2.5rem;
		}
	}
}

.displayHome {
	@extend .col-12;
	padding: 0 1rem;

	h1 {
		@extend .dotted; 
		font-size: 3.4375rem;
		@media screen and (max-width: 1500px) {
			font-size: 2.5rem;
		}
		@include media-breakpoint-down(md) {
			font-size: 1.625rem;
			line-height: 1.2;
			margin-bottom: 0;
		}
	}

	.w-14 {
		.custom-html {
			text-align: center;
			p:nth-of-type(1) {
				@extend .h2;
				@extend .section-title;
				@extend .dotted; 
				color: #fff;
				margin-top: 0;
				font-weight: normal;
			}
			p:nth-of-type(2) {
				@extend .h2;
				@extend .section-subtitle;
				color: #fff;
				font-weight: normal;
				margin-bottom: 2rem;
			}
		}	
	}
}


.displayCustomBanners2 {
	color: $primary;
	background-color: #F9F2E9;
	@extend .row ;
	@media screen and (max-width: 1200px) {
		margin-bottom: 2rem;
	}
}

;@import "sass-embedded-legacy-load-done:1576";