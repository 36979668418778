$component-name: breadcrumb;

.#{$component-name} {
  font-size: 1rem;
  font-weight: 400;
  padding: 1.5rem 0 0.5rem;
  font-family: Intro Head R;
  @include media-breakpoint-down(md) {
    font-size: .875rem;
  }

  a {
    color: $black;
  }

  &[data-depth="1"] {
    display: none;
  }

  &__wrapper {
    margin-bottom: 1rem;

    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }

    @at-root .page-order & {
      margin-bottom: 0;

      @include media-breakpoint-down(md) {
        margin-bottom: 0;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .page-product .breadcrumb-item:last-child {
    display: none;
  }
}

;@import "sass-embedded-legacy-load-done:1449";