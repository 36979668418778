$component-name: product-miniature;

.#{$component-name} {
  margin-bottom: 1.25rem;

  .wishlist-button-add {
    display: none;
  }

  .card {
    transition: 0.25s ease-out;
    background-color: transparent;
  }

  &:hover {
    .#{$component-name}__quickview {
      transform: translateY(-100%);
    }
  }

  .product-flags {
    z-index: 2;
  }

  & &__image-container {
    position: relative;
    overflow: hidden;
    border-radius: 0;
    // transition: 0.25s ease-out;
  }

  & &__image {
    // transition: 0.25s ease-out;
  }

  & &__link {
    position: relative;
    display: block;

    &:hover {
      .#{$component-name}__image {
        // transform: scale(1.1);
      }

      .#{$component-name}__image-container {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  & &__infos__right {
    padding-right: 0.625rem;
  }

  & &__title {
    display: block;
    color: $black;
    text-align: left;
    line-height: 1.1;
    font-size: 1.125rem;
    margin-bottom: .25rem;
    font-family: $font-family-highlight;
  }

  & &__availability {
    font-size: 0.875rem;
    i {
      font-size: 1rem;

      &.product-available {
        color:#00a400;
      }
    }
  }

  & &__discount-price {
    text-align: right;
  }

  & &__regular-price {
    font-size: 1rem;
    color: #838383;
    text-align: right;
  }
  & &__price {
    color: $black;
    font-weight: 600;
    font-size: 1rem;
    text-wrap: nowrap;
    margin-right: 0!important;
  }

  & &__quickview {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    padding: 0.5rem;
    background-color: var(--bs-white);
    transition: 0.25s ease-out;

    @media (hover: none) and (pointer: coarse) {
      display: none;
    }
  }

  & &__quickview_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    text-decoration: none;
    border: 0;

    .material-icons {
      margin-right: 0.25rem;
      font-size: 1.25rem;
    }
  }

  & &__quickview_touch {
    position: absolute;
    right: 0.635rem;
    bottom: 0.635rem;
    z-index: 10;
    display: none;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    box-shadow: 0.125rem -0.125rem 0.25rem 0 rgba(0, 0, 0, 0.2);

    @media (hover: none) and (pointer: coarse) {
      display: flex;
    }
  }

  .product-miniature-variants {
    display: none;
  }

  .variant-links {
    > a:first-child {
      margin-left: 0;
    }
  }
}


#js-product-list {
  .card {
    padding: .75rem;
    border: 1px solid #fff;

    &:hover,
    &:active,
    &:focus {
      border-color: #E0E0E0;
    }
  }
}


.swiper-products-crosssell {
  .product-flags {
    display: none;
  }
}
;@import "sass-embedded-legacy-load-done:1540";