.contact-form {
  .form-footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 0.5rem;
  }

  .contact-form-title {
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: .75rem;
    padding-bottom: .5rem;
    text-transform: uppercase;
    border-bottom: 1px solid #f5f5f5;

    .material-icons {
      margin-right: .625rem;
    }
  }
}

;@import "sass-embedded-legacy-load-done:1561";