.featured-products {
  .section-title {
    @extend .dotted;
  }

  .swiper-slide {
    margin-bottom: 0;
  }

  .swiper-new-products {
    &:before,
    &:after {
      content: "";
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      z-index: 3;
      backdrop-filter: blur(.5rem);
    }
    &:before {
      left: 0;
      width: 8.333%;
    }
    &:after {
      right: 0;
      width: 9%;
      @include media-breakpoint-down(md) {
        width: 10%;
      }
      @include media-breakpoint-down(sm) {
        width: 11%;
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 2.75rem;
    height: 2.75rem;
    background-color: transparent;

    &:after {
      font-family: 'Material Icons';
      content: '\e5cb';
      font-size: 2rem;
      color: $body-color;
    }
  }
  .swiper-button-prev {
    left: 3%;
  }
  .swiper-button-next {
    right: 3%;
    &:after {
      transform: rotate(180deg);
    }
  }

  .badge.new {
    display: none;
  }

  .product-miniature:not(:last-of-type) {
    border-right: 1px solid $light;
  }
}
;@import "sass-embedded-legacy-load-done:1556";