$component-name: customer-link;

.#{$component-name} {
  &__link,
  a {
    display: block;

    span {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      height: 100%;
      padding: 1.25rem 1rem;
      font-size: 1rem;
      font-weight: 600;
      color: $primary;
      background: $secondary;
      border-radius: var(--bs-border-radius-sm);
      transition: 0.25s ease-out;

      &:hover {
        color: var(--bs-white);
        background: var(--bs-primary);

        i {
          color: var(--bs-white);
        }
      }

      @include media-breakpoint-down(md) {
        flex-direction: row;
        align-items: center;
        padding: 0.5rem;
        text-align: left;
      }

      i {
        margin-bottom: 0.75rem;
        font-size: 1.5rem;
        color: $primary;
        transition: 0.25s ease-out;

        @include media-breakpoint-down(md) {
          margin-right: 0.75rem;
          margin-bottom: 0;
        }
      }
    }
  }

  &__logout {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;

    i,
    & {
      color: var(--bs-danger);
    }
  }
}

;@import "sass-embedded-legacy-load-done:1525";