.login {
  .forgot-password,
  &-create {
    text-align: center;
  }

  &__forgot-password {
    a {
      text-decoration: underline;
      &:hover,
      &:active,
      &:focus {
        color: $tertiary;
      }      
    }
  }

  hr {
    margin: 1rem auto;
  }

  .forgot-password {
    margin-bottom: 1.25rem;
  }
}

;@import "sass-embedded-legacy-load-done:1622";