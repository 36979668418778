$component-name: header-bottom;

.#{$component-name} {
  &__row {
    @include media-breakpoint-up(md) {
      // min-height: 4rem;
    }
  }

  &__h1 {
    font-size: unset;
  }
}

;@import "sass-embedded-legacy-load-done:1481";