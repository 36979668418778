.buttons_bottom_block {
  a {
    height: 100%;
    padding: .75rem;
  }
  .icon_svg:not(use) > svg {
    height: 1.875rem;
    width: 1.875rem;
  }
}
;@import "sass-embedded-legacy-load-done:1587";