$component-name: header-top;

.#{$component-name} {
  // overflow: hidden;
  --header-top-border-bottom: #{$header-top-border-bottom};
  --header-bottom-border-bottom: #{$header-bottom-border-bottom};

  .navbar-brand {
    @include media-breakpoint-down(md) {
      width: auto;
      padding: .5rem 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 11rem;
    }
  }

  a:not(.dropdown-item) {
    color: var(--header-top-color);

    &:hover {
      &,
      i {
        color: var(--bs-primary);
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .icon_svg:not(use) > svg {
      transform: scale(.75);
    }
  }

  &__right,
  &__left {
    @include media-breakpoint-up(md) {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  }

  &__right {
    font-size: 1.125rem;
    font-family: $font-family-highlight;
    @media screen and (min-width: 1200px) {
      padding-right: 8.333333%;
    }

    @include media-breakpoint-down(lg) {
      padding-right: 0;
    }

    .user-info {
      margin: 0 1rem;
      @include media-breakpoint-down(lg) {
        margin: 0;
      }
      .dropdown-toggle::after {
        display: none;
      }
    }

    .user-info a:not(.dropdown-item),
    .header-block__action-btn {
      display: flex;
      flex-direction: column;
    }
  }

  &__left {
    @media screen and (min-width: 1200px) {
      padding-left: 8.333333%;
    }
  }

  &__center {
    padding: 0;
    a {
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }

  &-desktop {
    @include media-breakpoint-down(md) {
      padding: .5rem 0;
    }
  }
}

.header-top {
  background-color: transparent;
}

.logo.img-fluid {
  width: 100%;
  max-width: 11.5rem;
  height: 6.75rem;
  transition: all .2s ease;

  @include media-breakpoint-down(lg) {
    height: 3.5rem;
  }
  @include media-breakpoint-down(md) {
    height: 1.0625rem;
  }

  
  &.logo-mini {
    display: none;
    max-width: inherit;
    @include media-breakpoint-down(lg) {
      display: block;
    }
  }
  
  &.logo-big {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}

// .header-block {
//   .dropdown-toggle:after {
//     width: .625rem;
//     height: .625rem;
//     background-color: $primary;
//     padding: 0;
//     border-radius: 50%;
//     border: none;
//     position: absolute;
//     bottom: .5rem;
//     right: .25rem;
//   }
// }


@keyframes slideInDown {
  0% {
    position: fixed;
    transform: translate3d(0, 0, 0);
  }
  10% {
    transform: translate3d(0, -110%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
} 


#header {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate3d(0, 0, 0);
  background-color: #fff;
  transition: transform .5s ease;

  // @include media-breakpoint-down(md) {
    // position: fixed;
  // }    

  &.scrolling {
    opacity: 0;
    position: fixed;
    transform: translate3d(0, -150%, 0);

    .header-top .container-fluid {
      background-color: #fff;
    }

    .header-top-desktop {
      padding: .5rem 0;
    }

    .header-bottom {
      padding-top: .5rem;
      padding-bottom: .5rem;
    }

    .logo-big {
      display: none;
    }

    .logo-mini {
      display: block;
      width: 327px;
      height: 36px;
      @include media-breakpoint-down(md) {
        height: 1.0625rem;
      }
    }
  }

  &.scrolling-fixed {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  .a-niveau1 img.adtm_menu_icon {
    width: 1.25rem;
    margin-right: .375rem;
  }

  .adtm_menu_container {
    width: 100%;
    background-color: $secondary;
    
    ul#menu li.li-niveau1 a.a-niveau1 {
      font-family: $font-family-highlight;
    }
  }
  
  #adtm_menu div.adtm_column_wrap span.column_wrap_title {
    margin-bottom: .75rem;
  }

  #adtm_menu ul#menu .li-niveau1 div.adtm_sub {
    padding: 0 .5rem;
  }

  #adtm_menu .adtm_column ul.adtm_elements li a {
    font-size: 1rem;
    margin-bottom: .375rem;
    &:hover,
    &:active,
    &:focus {
      color: $primary;
      text-decoration: underline;
    }
  }

  .li-niveau1 a span span {
    padding: 0;
  }

  .columnWrapTable + .row a,
  .adtm_column a {
    position: relative;
    display: block;

    img + span {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #fff;
      text-transform: initial;
      line-height: 1.2;
      font-size: 1.375rem;
      font-weight: 700;
      background: rgba(44,42,41,.4);
      padding: .5rem;
    }
  }
}

#mobileMenu {
  .columnWrapTable + .row {
    padding-right: .5rem;
    padding-left: .5rem;
    a {
      font-size: 16px;
      font-weight: bold;
      text-transform: none;
      color: $black;
    }
  }
}

.header-displayNav {
  position: relative;
  z-index: 10;
}

.displayNav2 {
  ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: .375rem 0;
  }
  &, a {
    font-size: 1rem;
    @include media-breakpoint-down(md) {
      font-size: .75rem;
    }
  }
  a {
    margin-left: 1rem;
  }
}

.icon_svg:not(use) > svg {
  height: 1.875rem;
  width: 1.875rem;
  fill: $black;
  @include media-breakpoint-down(md) {
    
  }
}

.btn-menu {
  margin-left: -.75rem;
}

.advtm_menu_close {
  display: none;
}

.header-link {
  @include media-breakpoint-down(md) {
    display: none;
  }
}
;@import "sass-embedded-legacy-load-done:1606";