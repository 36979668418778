$component-name: blockcart;

.#{$component-name} {
  &-modal {
    .modal-header {
      border-bottom: 0;
      justify-content: center;

      .modal-title,
      .btn-close {
        margin-left: auto;
      }
    }

    .modal-dialog {
      @include media-breakpoint-up(sm) {
        max-width: 550px;
      }

      @include media-breakpoint-up(md) {
        max-width: 750px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 1140px;
      }
    }

    .modal-title {
      display: inline-flex;
      align-items: stretch;
      font-size: calc(1rem + 0.1vw);

      i {
        font-size: 1.25rem;
        line-height: 1.25;
        color: inherit;
      }

      @include media-breakpoint-up(xl) {
        font-size: 1.25rem;

        i {
          font-size: 1.5rem;
        }
      }
    }

    &__total {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }

    &__summery {
      padding: 1rem;
      background-color: $gray-100;
      .product-total,
      .product-tax {
        font-weight: 700;
      }
    }

    &__product {
      img {
        border-radius: 8px;

        @include media-breakpoint-down(sm) {
          margin-bottom: 1rem;
        }
      }

      @include media-breakpoint-down(md) {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px dotted rgba($black, 0.2);
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:1468";