.addresses {
  &__new-address {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 1rem;
    border: 1px solid #f5f5f5;
    background-color: #f5f5f5;
    border-radius: 0.5rem;
    font-weight: bold;

    &:hover {
      i {
        transform: scale(1.2);
      }
    }

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 4rem;
      font-size: 2rem;
      color: #fff;
      background: $primary;
      border-radius: 50%;
      transition: 0.25s ease-out;

      @include media-breakpoint-down(sm) {
        width: 3rem;
        height: 3rem;
        font-size: 1.5rem;
      }
    }

    span {
      margin-bottom: 1rem;
    }
  }
}

;@import "sass-embedded-legacy-load-done:1629";