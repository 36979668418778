.page-product {
  background-repeat: no-repeat;
  background-size: 1920px auto;
  background-position: center top;

  .product-message {
    max-width: 17rem;
  }

  .product {
    &__col {
      position: -webkit-sticky;
      position: sticky;
      z-index: 9;
      height: 100%;
      top: 0;
    }

    &__left {
      @include media-breakpoint-up(md) {
        padding-right: 2.125rem;
      }
    }

    &__name {
      color: $black;
      text-align: left;
      font-size: 3.125rem;
      margin-bottom: 1rem;
      line-height: 1.2;
      @include media-breakpoint-down(lg) {
        font-size: 2.5rem;
      }
      @include media-breakpoint-down(md) {
        font-size: 2rem;
      }
    }

    &__manufacturer {
      font-family: Intro Head R;
      font-size: 1.25rem;
    }

    &__infos {
      .accordion-button {
        padding: 1.25rem 0;
        font-size: 1.25rem;
        font-weight: 700;
      }

      .accordion-body {
        padding: 0;
      }
    }

    &__images {
      margin-bottom: 1rem;

      .carousel {
        overflow: hidden;
        border: 1px solid #E0E0E0;
      }

      &__modal-opener {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      .zoom-in {
        display: none;
      }
    }

    .breadcrumb__wrapper {
      margin-bottom: 2rem;
    }

    &__description,
    &__description-short {
      ul {
        padding-left: 1.2rem;
        list-style: disc;
      }
      li {
        padding-left: .5rem;
      }
    }

    &__description-short {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.7;
      margin-bottom: .5rem;

      p {
        margin-bottom: .5rem;
      }
    }

    &__availability {
      font-size: .875rem;
      .product-available {
        color: #00C136;
      }
    }

    &__description {
      h2 {
        font-weight: 700;
      }

      h2, p, blockquote {
        margin-bottom: 1.875rem;
      }
    }

    &__tax-label {
      color: var(--bs-gray-500);
    }

    &__prices {
      color: $black;
      font-weight: 600;
      font-size: 1.75rem;
    }

    &__current-price {
    }

    &__discount {
      font-size: 1.25rem;
      color: var(--bs-gray-500);

      .product__discount-percentage,
      .product__discount-amount {
        color: #fff;
        padding: .375rem;
        font-weight: 700;
        font-size: .875rem;
        background-color: #FD1616;
      }
    }

    &__price-regular {
      text-decoration: line-through;
    }

    &__variants {
      .variant {
        margin-bottom: 1.25rem;

        .form-label {
          font-weight: 300;
          font-size: .75rem;
          letter-spacing: .125rem;
          text-transform: uppercase;
        }

        ul {
          display: flex;
          align-items: center;

          li {
            &:first-of-type {
              span {
                margin-left: 0;
              }
            }
          }
        }

        .input-color {
          display: none;
        }

        .color {
          span {
            font-size: 0;
          }
        }
      }
    }

    &-features {
      .col-md-6 + .col-md-6 {
        .d-flex.py-2 {
          @include media-breakpoint-down(md) {
            padding-left: 5.75rem !important;
          }
        }
      }
    }

    &-features {
      .col-md-6 > .d-flex:nth-of-type(odd),
      .pfi-product-features-images > .d-flex:nth-of-type(odd),
      .col-md-12 > .d-flex:nth-of-type(odd),
      .col-12 > .d-flex:nth-of-type(odd) {
        background-color: #fff;
      }
      .name {
        font-weight: 700;
        padding: .625rem 0;
      } 
      .value {
        padding: .5rem 0;
      }

      @include media-breakpoint-down(md) {
        .col-md-6 + .col-md-6 {
          margin-top: 3rem;
        }
        .name,
        .value{
          font-size: .875rem;
        }
      }
    }

    &__add-to-cart {
      margin-bottom: 1.5rem;

      .product-actions__button {
        @include media-breakpoint-down(sm) {
          margin-right: 0 !important;
        }

        button {
          width: 100%;
          font-size: 1rem;
          font-weight: 500;
          padding: 0.8125rem 1rem;
          letter-spacing: 0.1875rem;
          border-color: $highlight;
          background-color: $highlight;
          &:hover,
          &:active,
          &:focus,
          &:disabled {
            color: $white;
            border-color: $black;
            background-color: $black;
          }
          svg {
            height: 1.375rem;
            width: 1.375rem;
          }
          @include media-breakpoint-down(md) {
            font-size: .875rem;
            padding-left: .625rem;
            padding-right: .625rem;
            letter-spacing: 0.125rem;
            svg {
              width: 1.125rem;
            }
          }
        }
      }
      .buttons_bottom_block span {
        display: none;
      }
    }

    &-pack__item {
      border: 1px $gray-200 solid;
    }

    &__stores,
    &__delivery {
      color: $black;
      text-decoration: underline;
      margin-bottom: 1.5rem;
      a {
        font-weight: bold;
        text-decoration: underline;
        @media screen and (max-width:991px) {
          font-size: .875rem;
        }
      }
      &.icon_svg:not(use) > svg {
        fill: $black;
        height: 1.5rem;
        width: 1.5rem;
        flex-shrink: 0;
      }
    }

    &__additional-info {
      .buttons_bottom_block {
        display: none;
      }
    }
  }

  .attachments {
    .attachment {
      margin: .5rem 1rem;
      b {
        text-decoration: underline;
      }
    }
    .btn-secondary {
      color: $white;
      border-radius: 2rem;
      text-transform: inherit;
      border-color: $highlight;
      background-color: $highlight;
    }
    svg {
      margin-right: .75rem;
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    bottom: 0;
    top: inherit;
  }
  .carousel-control-prev {
    left: calc(50% - 3rem);
  }
  .carousel-control-next {
    right: calc(50% - 3rem);
  }

  @include media-breakpoint-down(md) {
    .carousel-control-prev,
    .carousel-control-next {
      width: 2rem;
      height: 2rem;
    }
    .carousel-control-prev {
      left: calc(50% - 2.5rem);
    }
    .carousel-control-next {
      right: calc(50% - 2.5rem);
    }
  }

  .nav-tabs {
    background-color: $tertiary;
    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 2;
    }
    .nav-link {
      margin: 0;
      border: none;
      color: #fff;
      font-weight: 400;
      font-size: 1.5625rem;
      font-family: Intro Head R;
      text-transform: uppercase;
      padding: .875rem 1.5rem .625rem;
      background-color: transparent;
      @media screen and (max-width:1200px) {
        font-size: 1.125rem;
        padding: 0.75rem .75rem 0.5rem;
      }
      &.active {
        border: none;
      }
    }
  }

  #description,
  #product-detail,
  #product-accessories,
  #attachments,
  #video,
  #reviews {
    scroll-margin-top: 50px;
  }

  #product-details {
    padding-left: 0;
    padding-right: 0;
  }

  #product-accessories {
    @include media-breakpoint-down(md) {
      .swiper-wrapper {
        flex-wrap: wrap;
        transform: none !important;
      }
      .swiper-navigation {
        display: none;
      }
      .swiper-wrapper .product-miniature:not(:last-of-type) {
        border-right: none;
      }
    }
  }

  #accordionAccess .container-fluid {
    padding: 0;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    @include media-breakpoint-down(md) {
      padding: 1rem;
    }
  }

  .accordion {
    @include media-breakpoint-down(md) {
      border-bottom: 1px solid $primary;
      &:first-child {
        border-top: 1px solid $primary;
      }
      &:last-child {
        border-bottom: none;
      }
    }

    .accordion-body {
      padding: 0;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      @include media-breakpoint-down(md) {
        padding: 1rem;
      }
    }

    .accordion-item {
      @include media-breakpoint-down(md) {
        padding: 0;
      }
      background-color: transparent;
      .tab .section-title {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    .accordion-button {
      font-weight: 600;
      padding: 0;
      font-size: 1rem;
      padding: 1.5rem 1rem;

      @include media-breakpoint-up(lg) {
        font-size: 1.3125rem;
        padding-bottom: 2rem;
      }

      // &:after {
      //   transform: var(--bs-accordion-btn-icon-transform);
      //   // transform: rotate(180deg);
      // }
      // &:not(.collapsed)::after {
      //   transform: rotate(0deg);
      // }
    }
  }

  .page-product-box.accordion {
    width: auto;
    .container-fluid {
      padding: 0;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      @include media-breakpoint-down(md) {
        padding: 1rem;
      }
    }
  }

  #blog_list_1-7 {
    justify-content: center;
  }
}

// Needs to be outside of the .page-product scope because it can be inside a modal
.thumbnails__container {
  width: calc(100% - 8rem);
  @include media-breakpoint-down(md) {
    width: calc(100% - 4rem)
  }
  &.swiper {
    padding-top: .5rem;
  }

  .swiper-wrapper {
    flex-wrap: nowrap;
  }

  .swiper-slide {
    width: 9.5rem;
    margin: 0;
    margin-bottom: 1rem;
    padding: 0 .5rem;
    @include media-breakpoint-down(md) {
      width: 6rem;
    }
  }
}

body:not(.page-cart) {
  .thumbnail-supp,
  .thumbnail {
    @include media-breakpoint-down(md) {
      width: 5rem;
    }
    &.active img {
      border: 1px solid $primary;
    }

    img {
      cursor: pointer;
      border: 1px solid #E0E0E0;
    }
  }
}

.product-accessories.pm-csoc {
  .thumbnail-supp,
  .thumbnail {
    @include media-breakpoint-down(md) {
      width: auto;
    }
  }
}

.swiper-wrap {
  .swiper-button-next,
  .swiper-button-prev {
    top: 2.875rem;
    width: 2.75rem;
    height: 2.75rem;
    background-color: transparent;
    @include media-breakpoint-down(md) {
      top: 1.5rem;
    }

    &:after {
      font-family: 'Material Icons';
      content: '\e5cb';
      font-size: 2rem;
      color: $body-color;
    }
  }
  .swiper-button-prev {
    left: .5rem;
    @include media-breakpoint-down(md) {
       left: 0;
    }
  }
  .swiper-button-next {
    right: .5rem;
    @include media-breakpoint-down(md) {
       right: 0;
    }
    &:after {
      transform: rotate(180deg);
    }
  }
}

.elementor {
  .section-title-elementor {
    .elementor-heading-title {
      @extend .dotted; 
      @extend .section-title;
      margin-bottom: 0;
    }
  }

  .section-subtitle-elementor {
    @extend .section-subtitle;
  }
}

.elementor-blog {
  .elementor-image {
    a {
      overflow: hidden;
    }
  }
}

.axproductsgroup {  
  p {
    font-weight: 700;
  }
  .dropdown-toggle {
    max-width: 100%;
    padding: 0 .5rem;
    font-size: 1rem;
    font-weight: normal;
    text-transform: initial;
    border: 1px solid #E0E0E0;
    &:hover,
    &:active,
    &:focus {
      color: $black;
      background-color: #fff;
    }
    &:after {
      display: none;
    }
  }
  .dropdown-item {
    padding: 0 .5rem;
  }

  & + .axproductsgroup {
    margin-top: 2rem!important;
  }
}

.page-product {
  .tab-content {
    & > .accordion,
    & > .axproductsgroup-wrapper,
    & > .elementor,
    & > .page-product-box {
      @extend .row;
      &:nth-of-type(even) {
        @extend .bg-beige;
      }
    }
    & > .axproductsgroup-wrapper > .col-xl-10 {
      width: 83.33333333%;
      @media screen and (max-width:1600px) {
        width: 100%;
      }
    }
  }
}

.blockcart-modal {
  @media (max-width: 575.98px) {
    .modal-fullscreen-sm-down {
      height: auto;
    }
  }
}


.product-miniature .product-miniature__infos__right > .skeepers_product__stars {
  width: auto;
  margin: .25rem 0 0;
  .skeepers_product__stars {
    width: auto;
    margin: 0;
  }
  span {
    font-size: .75rem;
  }
  .review__header {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .stars {
    margin-right: 6px;
  }
  .stars__rating {
    margin-left: 0;
    margin-right: 0;
  }
}

;@import "sass-embedded-legacy-load-done:1634";