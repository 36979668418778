// Slider target
$nouislider-target-border-width: 0;
$nouislider-target-border-color: var(--bs-gray-300);
$nouislider-target-border-radius: $border-radius;
$nouislider-target-background-color: var(--bs-gray-200);
$nouislider-target-shadow: null;

// Slider handle basic styles
$nouislider-handle-border-radius: 50%;
$nouislider-handle-border-color: var(--bs-gray-800);
$nouislider-handle-border-width: 1px;
$nouislider-handle-background-color: var(--bs-white);
$nouislider-handle-shadow: null;
// Slider handle active styles
$nouislider-handle-active-shadow: null;
$nouislider-handle-active-background-color: var(--bs-primary);

// Slider bar horizontal
$nouislider-horizontal-height: 4px;
$nouislider-horizontal-handler-width: 24px;
$nouislider-horizontal-handler-height: 24px;

// Slider bar vertical
$nouislider-vertical-width: 4px;
$nouislider-vertical-handler-width: 24px;
$nouislider-vertical-handler-height: 24px;

// Slider connect
$nouislider-connects-background-color: var(--bs-gray-700);
$nouislider-connects-border-radius: $border-radius;
$nouislider-connects-disabled-background-color: var(--bs-gray-200);

// Slider pips
$nouislider-pips-color: #999;
$nouislider-pips-value-color: #ccc;
$nouislider-pips-value-font-size: 10px;

// Slider pips horizontal
$nouislider-pips-horizontal-spacing: 10px 0;
$nouislider-pips-horizontal-height: 80px;

// Slider pips vertical
$nouislider-pips-vertical-spacing: 0 10px;
$nouislider-pips-vertical-height: 80px;

//Slider marker
$nouislider-marker-background-color: #ccc;
$nouislider-marker-sub-background-color: #aaa;
$nouislider-marker-large-background-color: #aaa;

//Slider marker horizontal
$nouislider-marker-horizontal-width: 2px;
$nouislider-marker-horizontal-height: 5px;
$nouislider-marker-sub-horizontal-height: 10px;
$nouislider-marker-large-horizontal-height: 15px;

//Slider marker vertical
$nouislider-marker-vertical-height: 2px;
$nouislider-marker-vertical-width: 5px;
$nouislider-marker-sub-vertical-width: 10px;
$nouislider-marker-large-vertical-width: 15px;

//Slider tooltips base
$nouislider-tooltips-border-color: $border-color;
$nouislider-tooltips-border-width: 1px;
$nouislider-tooltips-border-radius: $border-radius;
$nouislider-tooltips-background-color: #fff;
$nouislider-tooltips-color: $gray-900;
$nouislider-tooltips-padding: 1px 5px;
$nouislider-tooltips-font-size: 12px;
$nouislider-tooltips-font-weight: 600;

;@import "sass-embedded-legacy-load-done:1471";