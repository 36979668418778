$component-name: pagination;

.#{$component-name} {
  .page-item {
    &-number,
    .page-link {
      height: 100%;
      font-size: 1rem;
      font-weight: 400;
      color: $dark;
      border: none;
      margin-left: .125rem;
      margin-right: .125rem;
      padding: .5rem .75rem;
      @include media-breakpoint-down(lg) {
        margin-left: 0;
        margin-right: 0;
      }

      span {
        display: none!important;
      }
    }

    &.active,
    &:hover {
      .page-link {
        color: $primary;
        border-bottom: none;
        background-color: transparent;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:1519";