.quantity-button {
  padding: 0;
  border: .0625rem solid $gray-500;

  input {
    max-width: 2rem;
    height: 100%;
    padding: 0.875rem 0.5rem;
    text-align: center;
    border: none;
    color: $black;
    font-weight: 700;
    font-size: .875rem;
  }

  .input-group {
    height: 100%;
  }

  button {
    width: 2rem;
    padding: 0;
    color: $black;
    font-weight: 700;

    &:hover,
    &:active,
    &:focus {
      border: none;
    }

    &.increment {
      .confirmation {
        color: var(--bs-success);
      }
    }

    &.decrement {
      .confirmation {
        color: var(--bs-danger);
      }
    }

    &:hover {
      opacity: 0.6;
    }

    &:focus {
      box-shadow: none;
    }

    &:disabled {
      opacity: 1;
    }

    .material-icons {
      font-size: .875rem;
      pointer-events: none;
      padding: .875rem .5rem;
      width: auto;
      height: auto;
    }

    .spinner-border {
      border-width: 0.125rem;
    }
  }
}

;@import "sass-embedded-legacy-load-done:1455";