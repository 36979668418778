.elementor .contact-content {
  p {
    margin-bottom: 0.5rem;
  }

  hr {
    color: $black;
    margin: 1rem 0;
  }

  ul, ul li {
    margin-bottom: 0;
  }
}
;@import "sass-embedded-legacy-load-done:1650";