#content-wrapper {
  padding-bottom: 4rem;

  @include media-breakpoint-down(sm) {
    padding-bottom: 1rem;
  }

  .page-header {
    margin-bottom: 3rem;

    @include media-breakpoint-down(sm) {
      margin-bottom: 1rem;
    }
  }
}

hr {
  &:not(.alert-divider) {
    color: $light;
    opacity: 1;
    margin: 1.5rem 0;
  }
}

.elementor-widget-text-editor blockquote,
blockquote {
  position: relative;
  padding: 1.125rem;
  overflow: hidden;
  background-color: $secondary;

  &.blockquoteClaire, 
  &.blockquoteBastien {
    p:not(#e):nth-of-type(1) {
      padding-left: 12rem;
      padding-top: 1.5rem;
      @media screen and (max-width: 767px) {
        padding-left: 2rem;
        padding-top: 1rem;
      }
      &:before {
        left: 10rem;
        @media screen and (max-width: 767px) {
          left: 1rem;
        }
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 1.125rem;
      left: 1.125rem;
      width: 7rem;
      height: 7.75rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    
    cite  {
      padding-left: 9rem;
      @media screen and (max-width: 767px) {
        padding-left: 0;
      }
    }
  }

  &.blockquoteClaire {
    &:before {
      background-image: url("../../../../img/cms/lapiperit-claire.png");
    }
  }
  &.blockquoteBastien {
    &:before {
      background-image: url("../../../../img/cms/lapiperit-bastien.png");
    }
  }

  a {
    &:hover,
    &:active,
    &:focus {
      color: $primary;
      text-decoration: underline;
    }
  }

  p {
    margin-bottom: 1rem;

    &:not(#e) {
      padding-left: 2rem;
    }

    &:not(#e):nth-of-type(1) {
      padding-left: 3rem;
      padding-top: .5rem;
      padding-right: 3rem;
      font-size: 1.375rem;
      font-family: Intro Head R;
      @media screen and (max-width: 767px) {
        padding-right: 2rem;
        padding-left: 2rem;
        padding-top: 1rem;
      }
      &:before,
      &:after {
        font-size: 6rem;
        font-family: Intro Head R;
        position: absolute;
        top: 1rem;
        line-height: 1;
      }
      &:before {
        content: '“';
        left: 1rem;
      }
      &:after {
        content: '”';
        right: 1rem;
      }
    }
  }
}

cite {
  display: block;
  width: 100%;
  font-weight: 700;
  font-style: italic;
}


.elementor {
  .elementor-widget-text-editor ul {
    padding-left: 1.2rem;
    list-style: outside;
    li {
      margin-bottom: 0.5rem;
    }
  }
  // h2, .h2 {
  //   margin-bottom: 2rem;
  // }
  .swiper-container .swiper-slide figure img {
    width: auto;
  }
  .elementor-section-dark {
    &, h1, h2, h3, h4 {
      color: #fff;
    }
  }
  .elementor-swiper-button-prev {
    left: 0;
    @include media-breakpoint-up(md) {
      left: -40px;
    }
  }
  .elementor-swiper-button-next {
    right: 0;
    @include media-breakpoint-up(md) {
      right: -40px;
    }
  }
  .elementor-accordion .elementor-accordion-item {
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }
  .elementor-tab-desktop-title.elementor-active {
    background-color: $primary;
  }
  .elementor-tabs-content-wrapper {
    border: none;
  }
  .elementor-widget-tabs .elementor-tab-content {
    padding: 1rem 0;
    border: none!important;
  }
  .elementor-widget-tabs .elementor-tab-desktop-title {
    padding: 10px 20px;
  }
  .elementor-widget-tabs .elementor-tabs-wrapper {
    display: flex;
    justify-content: center;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .elementor-tab-mobile-title.elementor-active {
    @media screen and (max-width: 767px) {
      background-color: $primary;
      color: #fff!important;
      padding: 10px 20px;
      font-weight: bold;
    }
  }
  .elementor-blog-category {
    margin-bottom: .25rem!important;
    font-family: $font-family-highlight;
  }
  .elementor-blog-title {
    font-family: $font-family-highlight;
    &, & .elementor-heading-title {
      margin-bottom: .25rem!important;
    }
  }
  .elementor-blog-date {
    color: $gray-500;
    font-size: 0.875rem;
    margin-bottom: .75rem!important;
  }

  .elementor-widget-image .elementor-image > a {
    width: 100%;
  }

  .elementor-widget-wrap > .elementor-element.col-md-10 {
    @include media-breakpoint-up(md) {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
  }

  .elementor-fixe .swiper-wrapper {
    @media screen and (min-width:1025px) {
      transform: none !important;
      cursor: default;
    }
  }
}

;@import "sass-embedded-legacy-load-done:1617";