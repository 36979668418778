.product-flags-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  padding: .25rem 0;
}
.product-flags {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0;
}
.badge {
  &.out_of_stock {
    display: none;
  }
}
.fmm_title_text_sticker,
.badge {
  display: inline-block;
  font-weight: 800;
  font-size: .725rem;
  margin: 0 0 .25rem;
  padding: .25rem .75rem;
  text-transform: uppercase;
  background-color: $tertiary;

  @include media-breakpoint-down(md) {
    font-size: 0.625rem;
  }

  &.discount {
    color: #fff;
    background-color: #FD1616;
    border: 1px solid #FD1616;
  }
}
.product-flags-images {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0.25rem 0;
  span {
    display: block;
    margin-left: .375rem;
    margin-right: .375rem;
    margin-bottom: .375rem;
    text-align: center;
  }
  @include media-breakpoint-down(md) {
    img {
      height: 3.5rem;
    }
  }
}

.fmm_sticker_base_span p {
  max-width: 4rem;
  font-size: .5rem;
  font-weight: 780;
  line-height: 1.2;
  color: $black;
  text-align: center;
  text-transform: uppercase;
}

.product-miniature .fmm_title_text_sticker,
.product-miniature .badge {
  font-size: 0.625rem;
}
.product-miniature .product-flags-images {
  img {
      height: 2.25rem;
  }
  p {
    max-width: 3.5rem;
  }
}

;@import "sass-embedded-legacy-load-done:1452";