$component-name: carousel;

.#{$component-name} {
  .caption-description {
    p {
      color: var(--bs-white);
    }
  }
}

;@import "sass-embedded-legacy-load-done:1448";